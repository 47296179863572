import { useRef, useState } from "react";
import "../css/Header.css";
import HamburgerButton from "./HamburgerButton";
import HamburgerMenu from "./HamburgerMenu";
import HeaderLogo from "./HeaderLogo";
import useFocusTrap from "../hooks/useFocusTrap";

function Header({ dark = false, justHamburgerButton = false }) {
    const menuRef = useRef(null);
    const [menuState, setMenuState] = useState(false);
    useFocusTrap(menuRef, menuState, {
        initialFocus: "first",
    });

    function handleSetMenuState(val) {
        if (val) {
            menuRef.current.style.display = "flex";
            // menuRef.current.querySelector("a").focus();
        } else {
            setTimeout(() => {
                menuRef.current.style.display = "none";
            }, 500);
        }
        setMenuState(val);
    }

    return justHamburgerButton ? (
        <HamburgerButton dark={dark} menuRef={menuRef} />
    ) : (
        <div className="header">
            <HamburgerMenu
                menuRef={menuRef}
                setMenuState={handleSetMenuState}
                menuState={menuState}
            />
            <HamburgerButton
                dark={dark}
                menuRef={menuRef}
                menuState={menuState}
                setMenuState={handleSetMenuState}
            />
            <HeaderLogo dark={dark} />
            <HamburgerButton ghost={true} />
        </div>
    );
}

export default Header;
