export function orgPageSetup(pageRef, group) {
    function processPageForGroup() {
        processOrgCode();

        // if (Object.keys(orgData).includes(group)) {
        //     addOrgStyle(group);
        //     pageRef.current.classList.remove("default-org-page");
        //     pageRef.current.classList.add(group + "-org-page");
        // }

        // updateBackground(group);
        // showContainer(group);
        // showIcon(group);
    }

    function processOrgCode() {
        const orgCode = new URLSearchParams(window.location.search).get(
            "orgcode"
        );
        //dev
        /*
            const registerDomain = "https://v2.dev.geta-head.com";
            const registerPath = "/app/gah/#/register/orgcode";
            const defaultOrgCode = 'PATn';
            */

        //prod

        const registerDomain = "https://app.ca.geta-head.com";
        const registerPath = "/app/gah/#/register/orgcode";
        const defaultOrgCode = "qlYl";

        //staging
        /*
            const registerDomain = "https://v2.staging.geta-head.com";
            const registerPath = "/app/gah/#/register/orgcode";
            const defaultOrgCode = 'Jjph';
            */

        let registerURL = `${registerDomain}${registerPath}/${
            orgCode || defaultOrgCode
        }`;

        localStorage.setItem("registerurl", registerURL);
    }

    // function addOrgStyle(orgName, ref) {
    //     const rule = `
    //             .${orgName}-org-page .org-element:not(.${orgName}-org-element) {
    //                 display: none !important;
    //             }
    //         `;
    //     const styleElement = document.createElement("style");
    //     document.head.appendChild(styleElement);
    //     const sheet = styleElement.sheet;
    //     sheet.insertRule(rule);
    // }

    window.addEventListener("load", function () {
        //console.log('now in the gah program connect load');
        processPageForGroup();
    });
}

export const orgData = {
    peelregional: {
        name: "Peel Regional Police",
        customBanners: [],
        reels: [
            {
                name: "Liz Cordeiro",
                subtitle:
                    "Detective Sergeant, TPS (R), Volunteer, Boots on the Ground & Consultant",
            },
            {
                name: "Mark Dapat",
                subtitle:
                    "Deputy Chief of Community Safety & Well being Services Command, Peel Regional Police",
            },
            {
                name: "Mike Lockington",
                subtitle: "Staff Sergeant - Wellness Peel Region",
            },
            {
                name: "Ahad Bandealy",
                subtitle: "Founder, Keel Mind",
            },
        ],
    },
    yorkcism: {
        name: "York CISM",
        customBanners: ["hero"],
        logoSize: 250,
        reels: [
            {
                name: "Steve Horner",
                subtitle: "Education Committee Chair",
            },
            {
                name: "Jeremy Brewster",
                subtitle: "Team Lead",
            },
        ],
    },
    keel: {
        name: "Keel Mind",
        customBanners: [],
        logoSize: 150,
        logoAlt: "Keel Mind Challenge Coin",
        reels: [
            {
                name: "Liz Cordeiro",
                subtitle:
                    "Detective Sergeant, TPS (R), Volunteer, Boots on the Ground & Consultant",
            },
            {
                name: "Ahad Bandealy",
                subtitle: "Founder, Keel Mind",
            },
        ],
    },
    nypd: {
        name: "NYPD",
        customBanners: ["hero"],
        logoAlt: "NYPD Health and Wellness logo",
        reels: [
            {
                name: "Daniel Thompson",
                subtitle: "Chief of Police, Waukesha Police Department",
            },
            {
                name: "Heather Wurth",
                subtitle: "Chief of Police, Shorewood Police Department",
            },
            {
                name: "Mike Sasse",
                subtitle: "LEDR Team Member, President",
            },
        ],
    },
    ledr: {
        name: "LEDR",
        customBanners: ["hero"],
        reels: [],
        logoSize: 120,
    },
    ledrexecutive: {
        name: "LEDR Executive",
        customBanners: ["hero"],
        reels: [
            {
                name: "Daniel Thompson",
                subtitle: "Chief of Police, Waukesha Police Department",
            },
            {
                name: "Heather Wurth",
                subtitle: "Chief of Police, Shorewood Police Department",
            },
            {
                name: "Mike Sasse",
                subtitle: "LEDR Team Member, President",
            },
        ],
        logoSize: 120,
        loginLink: "https://app.us.geta-head.com/app/gah/",
    },
    waukesha: {
        name: "Waukesha Police Dept.",
        customBanners: ["hero"],
        reels: [
            { name: "Bryan Spakowicz", subtitle: "Sergeant" },
            { name: "Bryce Scholten", subtitle: "Detective" },
        ],
        logoSize: 120,
    },
    nycorrections: {
        name: "NY Dept. of Corrections",
        customBanners: ["hero", "buildingTrust"],
        reels: [
            {
                name: "Daniel Thompson",
                subtitle: "Chief of Police, Waukesha Police Department",
            },
        ],
        logoSize: 160,
    },
    windsor: {
        name: "Windsor Police Service",
        customBanners: ["hero"],
        reels: [
            {
                name: "Liz Cordeiro",
                subtitle:
                    "Detective Sergeant, TPS (R), Volunteer, Boots on the Ground & Consultant",
            },
            {
                name: "Daniel Thompson",
                subtitle: "Chief of Police, Waukesha Police Department",
            },
        ],
    },
    badgeoflife: {
        name: "Badge of Life",
        customBanners: [],
        reels: [],
    },
    nashvillefire: {
        name: "Nashville Fire Department",
        customBanners: ["hero"],
        reels: [
            {
                name: "Bryan Spakowicz",
                subtitle: "Sergeant, Waukesha Police Department",
            },
            {
                name: "Bryce Scholten",
                subtitle: "Detective, Waukesha Police Department",
            },
            {
                name: "Daniel Thompson",
                subtitle: "Chief of Police, Waukesha Police Department",
            },
        ],
    },
    mnpd: {
        name: "Metro Nashville Police Department",
        customBanners: ["hero"],
        reels: [
            {
                name: "Bryan Spakowicz",
                subtitle: "Sergeant, Waukesha Police Department",
            },
            {
                name: "Bryce Scholten",
                subtitle: "Detective, Waukesha Police Department",
            },
            {
                name: "Daniel Thompson",
                subtitle: "Chief of Police, Waukesha Police Department",
            },
        ],
    },
    firstnet: {
        name: "FirstNet",
        customBanners: ["hero"],
        reels: [],
    },
    njsacop: {
        name: "New Jersey State Association of Chief of Police",
        customBanners: ["hero"],
        reels: [],
    },
    macp: {
        name: "Michigan Association of Chief of Police",
        customBanners: [
            /*"hero"*/
        ],
        reels: [],
    },
};
