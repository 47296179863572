import "../css/ProgramChoice.css";
import { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProgramCard from "../components/ProgramCard";
import { useParams } from "react-router-dom";
import { orgData } from "../orgUtils";

function ProgramChoice() {
    const params = useParams();
    useEffect(() => {
        document.title = "Get Started | Keel Mind";
    });

    return (
        <div id="Program-Choice-page" className="basic">
            <Header dark={true} />
            <div className="content" role="main">
                <h1 id="wellness-journey">
                    Your Wellness Journey Begins Here...
                </h1>
                <div className="program-cards-container">
                    <ProgramCard
                        title={"Self Assessment Check."}
                        checks={[
                            "Fully anonymous wellness activity",
                            "Fun, easy, and instant results",
                        ]}
                        buttonText={"Start Self Assessment"}
                        url={"/readiness-checks"}
                        bigShadow={true}
                    >
                        Try our <strong>FREE and anonymous</strong> AI self
                        assessment activity, see your results in just a few
                        minutes.
                    </ProgramCard>
                    {params.org === "peelregional" && (
                        <ProgramCard
                            title={"Join the Keel Mind Platform."}
                            checks={[
                                "Connect with Peers of your choice",
                                "Confidential and Secure Chats",
                            ]}
                            buttonText={"Learn More"}
                            url={"/peer-support-program"}
                            outlineButton={true}
                        >
                            Connect with members of Peer Groups to receive
                            support, book sessions, and much more
                        </ProgramCard>
                    )}
                    {params.org === "ledrexecutive" && (
                        <ProgramCard
                            title={"Join the Keel Mind Platform."}
                            checks={[
                                "Connect with Peers of your choice",
                                "Confidential and Secure Chats",
                            ]}
                            buttonText={"Log In"}
                            url={
                                params.org === "ledrexecutive" &&
                                orgData[params.org]?.loginLink
                                    ? orgData[params.org].loginLink
                                    : "/peer-support-program"
                            }
                            orangeButton={true}
                        >
                            Connect with members of Peer Groups to receive
                            support, book sessions, and much more
                        </ProgramCard>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ProgramChoice;
